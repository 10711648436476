import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import {
	CalenderEvent,
	CountryList,
	CustomerAppointmentItem,
	CustomerAppointmentStaffPreferenceItem,
	CustomerAppointmentUpdateRequest,
	CustomerAppointmentVerificationItem,
	FetchCustomerAppointmentRequest,
	GetCalenderRequest,
	HolidayItem,
	InternalUserItem,
	IsUserWorkingElsewhere,
	IsUserWorkingElsewhereRequest,
	LanguagePreference,
	LanguagePreferenceItem,
	MeetingPreferenceItem,
	UserAvailabilityRequest,
} from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Result } from '../../../../../../goldstar-share/src/app/models/models';
import { ResultHelper } from '../../debit-card-application/common/result-extension';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { BranchItem, EntityGetRequest, ScheduleAppointmentCategoryItem } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import {
	AppointmentMeetingDetails,
	AppointmentMeetingMethod,
	AppointmentMeetingMethodType,
	AppointmentMessageType,
	AppointmentService,
	AppointmentServiceCategories,
	CustomerAppointmentMeetingPreferenceType,
	ScheduleAppointmentCustomerDetails,
	ScheduleAppointmentModel,
	ScheduleAppointmentStage,
} from '../models/models';
import { FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { CommonService } from './common.service';
import { SessionService } from '../../../services/session.service';
import es from '../../../../assets/i18n/es.json';
import en from '../../../../assets/i18n/en.json';
import { ClientAppStorageService } from '../../../../../../goldstar-share/src/app/services/client-app-storage.service';
import { LanguagePreferences } from '../../../shared/models/common.model';
import momentTimeZone from 'moment-timezone';

@Injectable({
	providedIn: 'root',
})
export class ScheduleAppointmentService {
	public isOTPVerificationSuccess: boolean = false;
	public showDoneMessage: boolean = false;
	customerStaffPreferenceList: CustomerAppointmentStaffPreferenceItem[] = [];
	public countryList: CountryList[] = [];
	public defaultServiceCategories: AppointmentServiceCategories[] = [];
	public defaultCurrentAppointmentStep: number = 0;
	public lstBranches: BranchItem[] = [];
	public holidayList: HolidayItem[] = [];
	public referencedEmployeeData!: InternalUserItem;
	public defaultTimeZone: string = 'America/Chicago';

	public operationHourList: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	public defaultAppointment: ScheduleAppointmentModel[] = [
		{
			id: '1',
			title: 'Service',
			type: ScheduleAppointmentStage.SERVICE,
			content: [],
		},
		{
			id: '2',
			title: 'Meeting Method',
			type: ScheduleAppointmentStage.MEETING_METHOD,
			content: [],
		},
		{
			id: '3',
			title: 'Staff Details',
			type: ScheduleAppointmentStage.STAFF_DETAILS,
			content: [],
		},
		{
			id: '4',
			title: 'Meeting Details',
			type: ScheduleAppointmentStage.MEETING_DETAILS,
			content: [],
		},
		{
			id: '5',
			title: 'Your Details',
			type: ScheduleAppointmentStage.YOUR_DETAILS,
			content: [],
		},
	];
	public meetingTypes: AppointmentMeetingMethodType[] = [];

	public defaultScheduleAppointmentContentList: ScheduleAppointmentCategoryItem[] = [];
	public currentAppointmentStep: BehaviorSubject<number> = new BehaviorSubject(this.defaultCurrentAppointmentStep);
	public currentAppointment: BehaviorSubject<ScheduleAppointmentModel[]> = new BehaviorSubject(JSON.parse(JSON.stringify(this.defaultAppointment)));
	public serviceCategories: BehaviorSubject<AppointmentServiceCategories[]> = new BehaviorSubject(this.defaultServiceCategories);
	public scheduleAppointmentContentList: BehaviorSubject<ScheduleAppointmentCategoryItem[]> = new BehaviorSubject(this.defaultScheduleAppointmentContentList);
	public selectedServiceCategories!: AppointmentServiceCategories | undefined;
	public defaultMeetingPreferenceList: MeetingPreferenceItem[] = [];
	public meetingPreferenceList: BehaviorSubject<MeetingPreferenceItem[]> = new BehaviorSubject(this.defaultMeetingPreferenceList);

	doneLoadingCategories: boolean = false;

	public scheduleAppointmentLanguagePreferenceStorageKey: string = 'SCHEDULE_APPOINTMENT';
	selectedLanguagePreference: string = '';
	public languageSupported: LanguagePreferences[] = [
		{
			name: 'English',
			localeCode: 'en',
		},
		{
			name: 'Spanish',
			localeCode: 'es',
		},
	];
	public languageList: LanguagePreference[] = [];
	public categoryIcons: Record<string, string> = {
		Personal_Banking: 'person',
		Commercial_Banking: 'business',
		Mortgage: 'cottage',
		International_Services: 'public',
	};
	public serviceIcons: Record<string, string> = {
		Open_Personal_Account: 'person_add',
		Apply_for_a_personal_loan: 'account_balance',
		Personal_Account_Help: 'manage_accounts',
		Commercial_Loan: 'assured_workload',
		Merchant_Services: 'business_center',
		Treasury_Management: 'attach_money',
		Open_Commercial_Account: 'domain_add',
		Commercial_Account_Help: 'assignment',
		Home_Purchase_Loan: 'home',
		Refinance: 'account_balance',
		Construction_to_Permanent_Mortgage: 'add_home',
		Mortgage_Lot_Loan: 'landscape',
		Foreign_Exchange: 'currency_exchange',
	};
	constructor(
		private apiV2: ApiService,
		private commonService: CommonService,
		private sessionService: SessionService,
		private storageService: ClientAppStorageService
	) {
		//generate session
		//this.initialize();
	}

	translateContent(content: string): string {
		const languagePref = this.storageService.tryLoadItem<string>(this.scheduleAppointmentLanguagePreferenceStorageKey) ?? 'en';

		switch (languagePref) {
			case 'es':
				const esKeys = Object.keys(es);
				const matchingEsKey = esKeys.find((x) => x == content);
				return (es as any)[matchingEsKey ?? ''];
			case 'en':
			default:
				const enKeys = Object.keys(en);
				const matchingKey = enKeys.find((x) => x == content);
				return (en as any)[matchingKey ?? ''];
		}
	}
	async initialize(referenceInternUserGUID: string, referenceServiceGUID: string) {
		await this.sessionService.generateSession();
		let request: EntityGetRequest = {};
		if (referenceInternUserGUID) {
			request.searchFilter = [
				{
					searchColumn: 'InternalUserGUID',
					searchColumnType: 'string',
					searchOption: '=',
					searchValue: referenceInternUserGUID,
					searchTable: 'InternalUserXCustomerAppointmentService',
				},
			];
		}
		if (referenceServiceGUID && !referenceInternUserGUID) {
			request.searchFilter = [
				{
					searchColumn: 'CustomerAppointmentServiceGUID',
					searchColumnType: 'string',
					searchOption: '=',
					searchValue: referenceServiceGUID,
					searchTable: 'CustomerAppointmentService',
				},
			];
		} else if (referenceServiceGUID && request.searchFilter && request?.searchFilter?.length > 0) {
			request.searchFilter.push({
				joinTerm: 'AND',
			});
			request.searchFilter.push({
				searchColumn: 'CustomerAppointmentServiceGUID',
				searchColumnType: 'string',
				searchOption: '=',
				searchValue: referenceServiceGUID,
				searchTable: 'CustomerAppointmentService',
			});
		}
		let masterApiRequests: any[] = [await this.fetchServicesAndCategories(request), await this.getHolidayList()];
		return await Promise.all(masterApiRequests)
			.then((response) => {
				if (response && response.length > 1) {
					return ResultHelper.successResponse('Data_Load_Success');
				} else {
					throw Error('Data_Load_Failure');
				}
			})
			.catch((error) => {
				console.log('Failed to load data');
				return ResultHelper.failedResponse(error);
			});
	}
	async fetchServicesAndCategories(entityRequest: EntityGetRequest): Promise<Result<string>> {
		if (this.serviceCategories && this.serviceCategories.value.length == 0) {
			let request: EntityGetRequest = {
				pageNumber: 1,
				pageSize: 1000,
			};
			if (entityRequest.searchFilter) {
				request.searchFilter = entityRequest.searchFilter;
			}
			await lastValueFrom(this.apiV2.scheduleAppointmentCategoryList({ body: request })).then(async (response) => {
				this.doneLoadingCategories = true;
				if (response.isSuccess) {
					const data = response.data?.items;
					if (data) {
						this.scheduleAppointmentContentList.next(data);
						let defaultServiceCategories: AppointmentServiceCategories[] = [];
						data.forEach((element) => {
							let index = defaultServiceCategories.findIndex((t) => t.serviceCategoryGUID == element.serviceCategoryGUID);
							if (index >= 0) {
								var serviceExist = defaultServiceCategories[index].appointmentService.find((s) => s.serviceGUID == element.serviceGUID);
								if (!serviceExist) {
									defaultServiceCategories[index].appointmentService.push({
										serviceGUID: element.serviceGUID ?? '',
										title: element.serviceLabel?.trim() ?? '',
										systemCode: element.serviceSystemCode?.trim() ?? '',
										duration: element.duration?.toString() ?? '',
										description: element.serviceDescription?.trim() ?? '',
										serviceCategoryGUID: element.serviceCategoryGUID ?? '',
									});
								}
							} else {
								defaultServiceCategories.push({
									serviceCategoryGUID: element.serviceCategoryGUID ?? '',
									title: element.serviceCategoryLabel?.trim() ?? '',
									systemCode: element.serviceCategorySystemCode ?? '',
									appointmentService: [
										{
											serviceGUID: element.serviceGUID ?? '',
											title: element.serviceLabel?.trim() ?? '',
											systemCode: element.serviceSystemCode?.trim() ?? '',
											duration: element.duration?.toString() ?? '',
											description: element.serviceDescription?.trim() ?? '',
											serviceCategoryGUID: element.serviceCategoryGUID ?? '',
										},
									],
								});
							}
						});
						this.serviceCategories.next(defaultServiceCategories);
					}
					return ResultHelper.successResponse('Successfully loaded data.');
				} else {
					return ResultHelper.failedResponse('Failed to load data.');
				}
			});
			return ResultHelper.failedResponse('Failed to load data.');
		}
		return ResultHelper.failedResponse('Failed to load data.');
	}
	clearData(schedulerStage: ScheduleAppointmentStage) {
		let appointmentData = this.currentAppointment.value;
		let defaultAppointmentIndex = appointmentData.findIndex((t) => t.type == schedulerStage);
		switch (schedulerStage) {
			case ScheduleAppointmentStage.SERVICE:
				appointmentData[defaultAppointmentIndex].content = [];
				appointmentData[defaultAppointmentIndex].service = undefined;
				break;
			case ScheduleAppointmentStage.MEETING_METHOD:
				appointmentData[defaultAppointmentIndex].content = [];
				appointmentData[defaultAppointmentIndex].meetingMethod = undefined;
				appointmentData[0].content = [
					{
						...appointmentData[0].content[0],
						subTitle: '',
						contentGUID: '',
					},
				];
				appointmentData[0].service = undefined;
				break;
			case ScheduleAppointmentStage.STAFF_DETAILS:
				appointmentData[defaultAppointmentIndex].content = [];
				appointmentData[defaultAppointmentIndex].staffDetails = undefined;
				break;
			case ScheduleAppointmentStage.MEETING_DETAILS:
				appointmentData[defaultAppointmentIndex].content = [];
				appointmentData[defaultAppointmentIndex].meetingDetails = undefined;
				break;
			default:
				break;
		}
		this.currentAppointment.next(appointmentData);
	}
	setSelectedCategory(selectedService: AppointmentServiceCategories, referenceInternalUserGUID?: string) {
		let appointmentData = this.currentAppointment.value;
		appointmentData[0].category = selectedService;
		appointmentData[0].content = [
			{
				title: selectedService.systemCode,
				subTitle: '',
				contentGUID: '',
			},
		];
		this.currentAppointment.next(appointmentData);
	}
	setSelectedService(selectedService: AppointmentService, referenceInternalUserGUID?: string) {
		let appointmentData = this.currentAppointment.value;
		appointmentData[0].service = selectedService;
		appointmentData[0].content = [
			{
				title: appointmentData[0].content[0].title,
				subTitle: selectedService.systemCode,
				contentGUID: selectedService.serviceGUID ?? '',
			},
		];
		this.currentAppointment.next(appointmentData);
		this.setMeetingTypesForService(selectedService, referenceInternalUserGUID);
		this.nextAppointmentStep();
	}
	clearScheduleAppointmentState() {
		this.currentAppointment.next([
			{
				id: '1',
				title: 'Service',
				type: ScheduleAppointmentStage.SERVICE,
				content: [],
			},
			{
				id: '2',
				title: 'Meeting Method',
				type: ScheduleAppointmentStage.MEETING_METHOD,
				content: [],
			},
			{
				id: '3',
				title: 'Staff Details',
				type: ScheduleAppointmentStage.STAFF_DETAILS,
				content: [],
			},
			{
				id: '4',
				title: 'Meeting Details',
				type: ScheduleAppointmentStage.MEETING_DETAILS,
				content: [],
			},
			{
				id: '5',
				title: 'Your Details',
				type: ScheduleAppointmentStage.YOUR_DETAILS,
				content: [],
			},
		]);
		this.currentAppointmentStep.next(0);
		this.selectedServiceCategories = undefined;
	}
	setMeetingTypesForService(selectedService: AppointmentService, referenceInternalUserGUID?: string) {
		if (referenceInternalUserGUID) {
			this.meetingTypes = this.meetingPreferenceList.value.map((t) => {
				let item: AppointmentMeetingMethodType = {
					serviceGUID: selectedService.serviceGUID ?? '',
					id: t.meetingPreferenceGUID ?? '',
					title: t.meetingPreferenceLabel ?? '',
					description: t.meetingPreferenceDescription ?? '',
					iconUrl: t.iconURL ?? '',
					systemCode: t.meetingPreferenceSystemCode ?? '',
				};
				return item;
			});
		} else {
			let meetingTypesData = this.scheduleAppointmentContentList.value.filter((t) => t.serviceGUID == selectedService.serviceGUID);
			this.meetingTypes = meetingTypesData
				.filter((obj, index, self) => index === self.findIndex((t) => t.serviceGUID == t.serviceGUID && t.meetingMethodGUID === obj.meetingMethodGUID))
				.map((t) => {
					let item: AppointmentMeetingMethodType = {
						serviceGUID: selectedService.serviceGUID ?? '',
						id: t.meetingMethodGUID ?? '',
						title: t.meetingMethodLabel ?? '',
						description: t.meetingMethodDescription ?? '',
						iconUrl: t.iconURL ?? '',
						systemCode: t.meetingMethodSystemCode ?? '',
					};
					return item;
				});
		}

		return this.meetingTypes;
	}

	async getMeetingPreferenceList(request: EntityGetRequest): Promise<MeetingPreferenceItem[] | undefined> {
		if (this.meetingPreferenceList.value.length == 0) {
			return await lastValueFrom(this.apiV2.getMeetingPreferenceList({ body: request }))
				.then(async (response) => {
					if (response.isSuccess && response.data?.items) {
						this.meetingPreferenceList.next(response.data.items);

						return response.data.items;
					} else return [];
				})
				.catch(() => {
					return [];
				});
		} else return this.meetingPreferenceList.value;
	}
	setMeetingMethodType(selectedMeetingMethodType?: AppointmentMeetingMethodType) {
		let appointmentData = this.currentAppointment.value;
		let meetingMethodIndex = appointmentData.findIndex((t) => t.type == ScheduleAppointmentStage.MEETING_METHOD);
		if (selectedMeetingMethodType) {
			let meetingTypesData = this.scheduleAppointmentContentList.value.filter(
				(t) => t.serviceGUID == selectedMeetingMethodType.serviceGUID && t.meetingMethodGUID == selectedMeetingMethodType.id
			);
			let meetingMethod: AppointmentMeetingMethod = {
				id: selectedMeetingMethodType.id,
				meetingMethodType: selectedMeetingMethodType,
				canSkipLocation: (meetingTypesData.length > 0 && meetingTypesData[0].allowSkipLocation) ?? false,
			};
			appointmentData[meetingMethodIndex].meetingMethod = meetingMethod;
			appointmentData[meetingMethodIndex].content = [
				{
					title: selectedMeetingMethodType.systemCode ?? '',
					subTitle: '',
					contentGUID: selectedMeetingMethodType.id,
				},
			];
			this.currentAppointment.next(appointmentData);
			this.nextAppointmentStep();
		} else {
			appointmentData[meetingMethodIndex].meetingMethod = undefined;
			appointmentData[meetingMethodIndex].content = [];
			this.currentAppointment.next(appointmentData);
		}
	}
	nextAppointmentStep(nextStepCount?: number) {
		let appointmentStageToUpdate = this.currentAppointmentStep.value;
		if (nextStepCount) {
			appointmentStageToUpdate = appointmentStageToUpdate + nextStepCount;
		} else {
			appointmentStageToUpdate += 1;
		}
		this.currentAppointmentStep.next(appointmentStageToUpdate);
	}
	previousAppointmentStep(step?: number) {
		let appointmentStageToUpdate = this.currentAppointmentStep.value;
		if (step) {
			appointmentStageToUpdate = step;
		} else {
			appointmentStageToUpdate -= 1;
		}
		this.currentAppointmentStep.next(appointmentStageToUpdate);
	}
	staffPreferenceSelected(selectedStaffPreference: CustomerAppointmentStaffPreferenceItem) {
		let appointmentData = this.currentAppointment.value;
		let indexOfStaffDetails = appointmentData.findIndex((t) => t.type == ScheduleAppointmentStage.STAFF_DETAILS);
		if (indexOfStaffDetails >= 0) {
			appointmentData[indexOfStaffDetails].staffDetails = {
				...appointmentData[indexOfStaffDetails].staffDetails,
				staffPreference: selectedStaffPreference,
			};
			this.currentAppointment.next(appointmentData);
		}
	}
	staffMemberSelected(selectedStaffMember: InternalUserItem) {
		let appointmentData = this.currentAppointment.value;
		let indexOfStaffDetails = appointmentData.findIndex((t) => t.type == ScheduleAppointmentStage.STAFF_DETAILS);
		if (indexOfStaffDetails >= 0) {
			appointmentData[indexOfStaffDetails].content = [
				{
					title: selectedStaffMember.name ?? '',
					subTitle: selectedStaffMember.titleName ?? '',
					contentGUID: selectedStaffMember.internalUserGUID ?? '',
				},
			];
			appointmentData[indexOfStaffDetails].staffDetails = {
				...appointmentData[indexOfStaffDetails].staffDetails,
				internalUser: selectedStaffMember,
			};
			this.currentAppointment.next(appointmentData);
		}
	}
	async fetchAllBranchTypes(serviceGUID: string): Promise<BranchItem[]> {
		const staffDetailsData = this.currentAppointment.value.find((x) => x.type === ScheduleAppointmentStage.STAFF_DETAILS);
		let languageGUID = '';
		if (staffDetailsData?.staffDetails?.languagePreference) {
			languageGUID = staffDetailsData.staffDetails.languagePreference?.languagePreferenceGUID ?? '';
		}
		let request: EntityGetRequest = {
			pageNumber: 1,
			pageSize: 1000,
			searchFilter: [
				{
					searchColumn: 'CustomerAppointmentServiceGUID',
					searchValue: serviceGUID,
					searchTable: 'InternalUserXCustomerAppointmentService',
					searchOption: '=',
					searchColumnType: 'string',
				},
				{
					joinTerm: 'and',
				},
				{
					searchOption: '=',
					searchColumn: 'languagePreferenceGUID',
					searchColumnType: 'string',
					searchTable: 'InternalUserXLanguagePreference',
					searchValue: languageGUID ?? '',
				},
				{
					joinTerm: 'and',
				},
				{
					searchOption: '=',
					searchColumn: 'ActiveYN',
					searchColumnType: 'string',
					searchTable: 'InternalUser',
					searchValue: 'Y',
				},
			],
		};

		await lastValueFrom(this.apiV2.listBranchServices({ body: request })).then(async (response) => {
			if (response.isSuccess) {
				if (response.data?.items) {
					this.lstBranches = response.data?.items;
				}
			}
		});
		return this.lstBranches;
	}
	public async fetchCountryList(): Promise<Result<CountryList[]>> {
		if (this.countryList.length == 0) {
			return await lastValueFrom(this.apiV2.countryList()).then((response) => {
				if (response.isSuccess) {
					this.countryList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<CountryList[]>('Failed to load data.');
				}
			});
		} else return ResultHelper.successResponse(this.countryList);
	}

	public async getCustomerStaffPreferenceList(): Promise<Result<CustomerAppointmentStaffPreferenceItem[]>> {
		if (this.customerStaffPreferenceList.length == 0) {
			return await lastValueFrom(this.apiV2.getCustomerStaffPreferenceList()).then((response) => {
				if (response.isSuccess) {
					this.customerStaffPreferenceList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<CustomerAppointmentStaffPreferenceItem[]>('Failed to load data.');
				}
			});
		} else return ResultHelper.successResponse(this.customerStaffPreferenceList);
	}

	public async fetchAllUsersData(request: EntityGetRequest): Promise<Result<InternalUserItem[]>> {
		try {
			return await lastValueFrom(this.apiV2.userListForScheduleAppointment({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalUserItem[]>('Failed to load data.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalUserItem[]>(error);
		}
	}

	public async fetchAllLanguagePreference(serviceGUID: string): Promise<Result<LanguagePreferenceItem[]>> {
		try {
			return await lastValueFrom(
				this.apiV2.languagePreferenceList_1({
					body: {
						serviceGUID,
					},
				})
			).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<LanguagePreferenceItem[]>('Failed to load data.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<LanguagePreferenceItem[]>(error);
		}
	}

	async checkIfMobileNumberIsNeeded(form: FormGroup, controlNames: string[]): Promise<boolean> {
		const appointmentData = this.currentAppointment.value;
		const meetingMethodIndex = appointmentData.findIndex((t) => t.type === ScheduleAppointmentStage.MEETING_METHOD);

		if (meetingMethodIndex > -1) {
			const meetingMethodData = appointmentData[meetingMethodIndex].meetingMethod;
			if (meetingMethodData) {
				const isMobileNumberRequired = meetingMethodData.meetingMethodType.systemCode === CustomerAppointmentMeetingPreferenceType.PHONE;

				controlNames.forEach((controlName) => {
					if (isMobileNumberRequired) {
						form.controls[controlName].setValidators([Validators.required, this.commonService.phoneNumberValidator()]);
					} else {
						form.controls[controlName].clearValidators();
					}
					form.controls[controlName].updateValueAndValidity();
				});
				return isMobileNumberRequired;
			}
		}
		return false;
	}

	setMeetingDetails(meetingDetails: AppointmentMeetingDetails) {
		try {
			let appointmentData = this.currentAppointment.value;
			let index = appointmentData.findIndex((t) => t.type == ScheduleAppointmentStage.MEETING_DETAILS);
			if (index > -1) {
				const formattedDate = moment(meetingDetails.selectedDate, 'MM/DD/YYYY').format('dddd, MMMM D, YYYY');
				let duration = 30;
				const serviceIndex = appointmentData.findIndex((t) => t.type === ScheduleAppointmentStage.SERVICE);
				if (serviceIndex > -1 && appointmentData[serviceIndex].service?.duration) {
					duration = parseInt(appointmentData[serviceIndex].service?.duration ?? '30');
				}

				const startTime = moment(meetingDetails.timeSlot.time, 'hh:mm A');
				const endTime = startTime.clone().add(duration, 'minutes');
				const combinedStartDateTime = this.combineDateAndTime(meetingDetails.selectedDate, meetingDetails.timeSlot.time!);
				const startDateTime = combinedStartDateTime || null;
				const endDateTime = startDateTime ? startDateTime.clone().add(duration, 'minutes') : null;
				meetingDetails.startDateTime = startDateTime ? startDateTime.format() : '';
				meetingDetails.endDateTime = endDateTime && endDateTime.isValid() ? endDateTime.format() : '';
				appointmentData[index].meetingDetails = meetingDetails;

				const formattedStartTime = startTime.format('h:mm A');
				const formattedEndTime = endTime.format('h:mm A');
				const formattedDuration = `${formattedStartTime} - ${formattedEndTime} (${meetingDetails.timeZoneAbbreviation})`;
				appointmentData[index].content = [
					{
						contentGUID: '',
						title: 'Date & Time',
						subTitle: meetingDetails.selectedDate,
						extraContent: formattedDuration,
					},
				];

				this.currentAppointment.next(appointmentData);
				this.nextAppointmentStep();
			}
		} catch (error) {
			throw error;
		}
	}

	combineDateAndTime(dateStr: string, timeStr: string) {
		const dateMoment = moment(dateStr, 'MM/DD/YYYY', true);
		const timeMoment = moment(timeStr, 'HH:mm A', true);

		if (!dateMoment.isValid() || !timeMoment.isValid()) {
			throw Error(`cannot parse date ${dateStr} ${timeStr} `);
		}

		return dateMoment
			.set({
				hour: timeMoment.hour(),
				minute: timeMoment.minute(),
				second: 0,
				millisecond: 0,
			})
			.isValid()
			? dateMoment
			: null;
	}

	public async getHolidayList(): Promise<Result<HolidayItem[]>> {
		if (this.holidayList.length == 0) {
			return await lastValueFrom(this.apiV2.holidayList()).then((response) => {
				if (response.isSuccess) {
					this.holidayList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<HolidayItem[]>('Failed to load data.');
				}
			});
		} else return ResultHelper.successResponse(this.holidayList);
	}

	public async addOrUpdateCustomerAppointment(request: CustomerAppointmentItem): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.customerAppointmentAddOrUpdate({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				} else if (response.message && response.message.includes('Invalid Phone Number')) {
					throw 'Invalid Phone Number';
				} else {
					throw 'Schedular-Appointment-Booked-Failed';
				}
			})
			.catch((error: any) => {
				if (error && error.includes('Invalid Phone Number')) {
					return ResultHelper.failedResponse('Invalid_Phone_Number');
				}
				return ResultHelper.failedResponse('Schedular-Appointment-Booked-Failed');
			});
	}

	saveCustomerDetailsState(customerAppointmentDetails: ScheduleAppointmentCustomerDetails) {
		let appointmentData = this.currentAppointment.value;
		let customerDetailsIndex = appointmentData.findIndex((t) => t.type == ScheduleAppointmentStage.YOUR_DETAILS);
		if (customerDetailsIndex >= 0) {
			appointmentData[customerDetailsIndex].customerDetails = customerAppointmentDetails;
		}
	}

	public async loadUserAvailabilityList(request: UserAvailabilityRequest): Promise<Result<any>> {
		const meetingType: any = this.currentAppointment.value.find((x) => x.type === 'MEETING_METHOD');
		if (meetingType && meetingType.meetingMethodType && meetingType.meetingMethodType.systemCode) {
			request.meetingType = meetingType.meetingMethodType.systemCode;
		}
		return await lastValueFrom(this.apiV2.getUserAppointmentAvailabilityList({ body: request })).then((response) => {
			if (response.isSuccess && response.data) {
				return ResultHelper.successResponse<any>(response.data);
			} else {
				return ResultHelper.failedResponse<any>('Failed to fetch branch data');
			}
		});
	}
	public async fetchAppointmentDetails(customerAppointmentGUID: string): Promise<Result<CustomerAppointmentItem>> {
		return await lastValueFrom(
			this.apiV2.getScheduledCustomerAppointmentDetails({
				body: {
					customerAppointmentGUID,
				},
			})
		).then((response) => {
			if (response.isSuccess && response.data && response.data.items) {
				return ResultHelper.successResponse(response.data?.items[0]);
			} else {
				return ResultHelper.failedResponse<CustomerAppointmentItem>('Failed to load data.');
			}
		});
	}

	public async getCustomerAppointmentList(request: FetchCustomerAppointmentRequest): Promise<Result<CustomerAppointmentItem[]>> {
		try {
			return await lastValueFrom(this.apiV2.getCustomerAppointmentList({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<CustomerAppointmentItem[]>('Failed to load data.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<CustomerAppointmentItem[]>(error);
		}
	}

	public async updateCustomerAppointmentSchedule(request: CustomerAppointmentUpdateRequest): Promise<Result<string>> {
		request.messageType = AppointmentMessageType.RE_SCHEDULE_AND_UPDATE_MEETING_TYPE_APPOINTMENT;
		return await lastValueFrom(this.apiV2.updateCustomerAppointmentSchedule({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process update request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async updateCustomerAppointmentMeetingPreference(request: CustomerAppointmentUpdateRequest): Promise<Result<string>> {
		request.messageType = AppointmentMessageType.RE_SCHEDULE_AND_UPDATE_MEETING_TYPE_APPOINTMENT;
		return await lastValueFrom(this.apiV2.updateCustomerAppointmentMeetingPreference({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process update request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
	public async cancelCustomerAppointment(request: CustomerAppointmentUpdateRequest): Promise<Result<string>> {
		request.messageType = AppointmentMessageType.CANCEL_APPOINTMENT;
		return await lastValueFrom(this.apiV2.cancelCustomerAppointment({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process update request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async fetchAllUsersCalenderEvents(request: GetCalenderRequest[]): Promise<Result<CalenderEvent[]>> {
		try {
			return await lastValueFrom(this.apiV2.fetchAllUserCalenderEvent({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<CalenderEvent[]>('Failed to load data.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<CalenderEvent[]>(error);
		}
	}

	public async verifyCustomerAppointment(customerAppointmentGUID: string): Promise<Result<CustomerAppointmentVerificationItem>> {
		try {
			return await lastValueFrom(
				this.apiV2.verifyCustomerAppointment({
					body: {
						customerAppointmentGUID: customerAppointmentGUID,
					},
				})
			).then((response) => {
				if (response.isSuccess && response.data) {
					return ResultHelper.successResponse(response.data);
				} else {
					return ResultHelper.failedResponse<CustomerAppointmentVerificationItem>(response.message ?? 'Failed to locate appointment details.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<CustomerAppointmentVerificationItem>('Failed to locate appointment details.');
		}
	}

	async getReferencedEmployeeData(referenceInternUserGUID: string) {
		//let service = this.currentAppointment.value.find((t) => t.type == ScheduleAppointmentStage.SERVICE)?.service;
		//if (service && service.serviceGUID) {
		let entityGetRequest: EntityGetRequest = {
			searchFilter: [
				{
					searchOption: '=',
					searchColumn: 'InternalUserGUID',
					searchColumnType: 'string',
					searchTable: 'InternalUser',
					searchValue: referenceInternUserGUID ?? '',
				},
			],
		};
		const response = await this.fetchAllUsersData(entityGetRequest);
		if (response && response.data) {
			this.referencedEmployeeData = response.data[0];
		}
		//}
	}

	public async sendAppointmentMessage(request: CustomerAppointmentUpdateRequest): Promise<Result<string>> {
		try {
			return await lastValueFrom(this.apiV2.sendAppointmentMessage({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				} else {
					return ResultHelper.failedResponse<string>('Failed send appointment message');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>(error);
		}
	}

	public async isInternalUserWorkingElsewhere(request: IsUserWorkingElsewhereRequest) {
		try {
			return await lastValueFrom(this.apiV2.isUserWorkingElsewhere({ body: request }));
		} catch (error: any) {
			return error;
		}
	}

	public translateDay(day: string, locale: string): string {
		if (locale === 'es') {
			const esKeys = Object.keys(es);
			let spanishDayKey: string | undefined = esKeys.find((key) => key === day.toUpperCase()); //this.SpanishDay[timeArray[0]];
			let spanishDay: string | undefined = (es as any)[spanishDayKey ?? ''];
			return spanishDay || day;
		}
		return day;
	}

	public formatDate(dateString: string, locale: string, shortDate?: boolean, format?: string) {
		let formattedDateString: string = momentTimeZone.tz(dateString, 'America/Chicago').format(format || 'dddd, MMMM DD, YYYY, h:mm A');
		if (locale === 'es') {
			const esKeys = Object.keys(es);
			console.log(esKeys);
			let timeArray: string[] = formattedDateString.split(',');
			let spanishDayKey: string | undefined = esKeys.find((key) => key === timeArray[0].toUpperCase()); //this.SpanishDay[timeArray[0]];
			let spanishDay: string | undefined = (es as any)[spanishDayKey ?? ''];
			console.log(spanishDay);
			if (spanishDay) {
				timeArray[0] = spanishDay;
			}
			if (shortDate) {
				let monthAndDay: string[] = timeArray[1].split(' ');
				let spanishMonthKey: string | undefined = esKeys.find((key) => key === monthAndDay[1].toUpperCase());
				let spanishMonth: string | undefined = (es as any)[spanishMonthKey ? spanishMonthKey + '_SHORT' : ''];
				if (spanishMonth) {
					monthAndDay[1] = spanishMonth;
				}
				timeArray[1] = monthAndDay.join(' ');
			} else {
				let monthIndex = 1;
				if (format == 'MMMM d, y') {
					monthIndex = 0;
				}
				let monthAndDay: string[] = timeArray[monthIndex].split(' ');
				let spanishMonthKey: string | undefined = esKeys.find((key) => key === monthAndDay[monthIndex].toUpperCase());
				let spanishMonth: string | undefined = (es as any)[spanishMonthKey ?? ''];
				if (spanishMonth) {
					monthAndDay[monthIndex] = spanishMonth;
				}
				timeArray[monthIndex] = monthAndDay.join(' ');
			}

			formattedDateString = timeArray.join(',');
		}
		if (!format) {
			let splitDateString: string[] = formattedDateString.split(',');
			splitDateString = splitDateString.slice(0, splitDateString.length - 1);
			return splitDateString.join(',');
		} else {
			return formattedDateString;
		}
	}

	public async fetchAllLanguages(): Promise<Result<LanguagePreference[]>> {
		try {
			if (this.languageList.length > 0) {
				return ResultHelper.successResponse(this.languageList);
			}
			return await lastValueFrom(this.apiV2.languagePreferenceList()).then((response) => {
				if (response.isSuccess) {
					this.languageList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<LanguagePreference[]>('Failed to load data.');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<LanguagePreference[]>(error);
		}
	}
}
